import React from 'react';
import styled from 'styled-components';
import Link from '../../../src/Components/Link';
import * as Icon from '../../../src/Components/Icon';
import { connect } from 'react-redux'
import Selectors from '../../../src/Selectors'
import Login from '../../../src/Components/Login'
import * as Widget2 from '../../../src/Components/Widget2'
import * as Widget from '../../../src/Components/Widget'
import ActionCreator from '../../../src/ActionCreator';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';

export default function NavbarFactory({
  coverContent = true,
  background = 'rgba(0,0,0,0)',
  altBackground = '#0079ff',
  textColor = 'white',
  textShadow = '2px 2px #ccc',
  height = 60,
} = {}) {
  class Navbar extends React.Component {
    state = {
      drawerOpen: false,
    }

    render() {
      let { passBreakpoint, profile, appActions, navActions } = this.props;
      let menus = [
      ]

      if (profile) {
        menus = [
          ...menus,
          { name: 'Hot Topic', url: '/HotTopic' },
          { name: 'IVF Physician', url: '/IVFPhysician' },
          { name: 'Embryologist', url: '/video-category/?id=5' },
        ]
      }


      return (
        <Style.Wrapper passBreakpoint={passBreakpoint}>
          <Style.Content>
            <a href='/'><img src={"/images/merck-title.png"} width={135} height={22} /></a>

            {/* Large screen */}
            <div className="large-screen-navs">
              {
                <>
                  {
                    menus.map((item, idx) => {
                      return (
                        <Widget2.FlatButton key={idx} color='white' onClick={() => navActions.push(item.url)}>
                          {item.name}
                        </Widget2.FlatButton>
                      )
                    })
                  }
                </>
              }{
                profile ? (
                  <>
                    <Link to="/me" className="nav-item" extraStyle={{ marginLeft: 20 }}>
                      <Icon.Person color='white' />
                    </Link>
                    <Widget2.FlatButton
                      color='white'
                      css='margin-left: 0px;'
                      onClick={() => {
                        appActions.logout()
                          .then(() => navActions.push('/'))
                        this.setState({ open: true })
                      }}
                    >
                      登出
                  </Widget2.FlatButton>
                  </>
                ) : (
                    <Login.Button />
                  )
              }
            </div>

            {/* Small screen */}
            <div className="small-screen-navs">
              <Widget.IconButton
                onClick={() => this.setState({ drawerOpen: !this.state.drawerOpen })}
              >
                <Icon.Apps color={'white'} />
              </Widget.IconButton>

              <Drawer open={this.state.drawerOpen}>
                {
                  menus.map((item, idx) => {
                    return (
                      <MenuItem key={idx} onClick={() => navActions.push(item.url)}>
                        {item.name}
                      </MenuItem>
                    )
                  })
                }
                {
                  profile ? (
                    <MenuItem onClick={() => {
                      appActions.logout()
                        .then(() => navActions.push('/'))
                      this.setState({ drawerOpen: false })
                    }}
                      style={{ color: 'green' }}
                    >
                      登出
                </MenuItem>
                  ) : (

                      <MenuItem>
                        <Login.Button />
                      </MenuItem>
                    )
                }

              </Drawer>
            </div>


          </Style.Content>
        </Style.Wrapper>
      );
    }
  }

  const Style = {
    Wrapper: styled.div`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      padding: 0px 20px;
      z-index:10;
      background: ${props => props.passBreakpoint ? altBackground : background};
      transition: background 200ms ease-in-out;
      background-color:#503291;
    `,

    Content: styled.div`
      margin: 0 auto;
      max-width: 1024px;
      height: ${height}px;
      display: flex;
      align-items: center;

      & > .site-title {
        color: ${textColor};
        text-decoration: none;
        font-size: 30px;
      }

      & > .large-screen-navs {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        & > .nav-item {
          margin-right: 15px;
          color: ${textColor};
          text-shadow: ${textShadow};
          text-decoration: none;
          display: flex;
          align-items: center;
        }

        & > .nav-item :last-child {
          margin-right: 0px;
        }
      }

      & > .small-screen-navs {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }

      @media screen and (max-width: 800px) {
        & > .large-screen-navs {
          display: none;
        }
      }

      @media screen and (min-width: 800px) {
        & > .small-screen-navs {
          display: none;
        }
      }
    `
  }

  return connect(
    (state) => ({
      profile: Selectors.getLoginUser(state),
    }), ActionCreator
  )(Navbar);
}
