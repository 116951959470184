import React, { Component } from 'react';
import '../../src/index.css';
import styled from 'styled-components';
import MuiWrapper from '../../src/MuiWrapper';
import AppConfig from '../../src/Config'
import NavbarFactory from '../../src/Components/Navbar';
import FooterFactory from '../../src/Components/Footer';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Selectors from '../../src/Selectors'
import ActionCreator from '../../src/ActionCreator'
import FullScreenPopup from '../Components/FullScreenPopup'

const Navbar = NavbarFactory(AppConfig.navbar);
const Footer = FooterFactory(AppConfig.footer);

class Layout extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onScroll);
  }

  render() {
    let {
      location: { pathname },
      profile,
      is_privacy_popup_display,
      appActions,
    } = this.props;
    let passBreakpoint = !!this.passBreakpoint;
    pathname = this._normalizePath(pathname);

    console.log('>>>>>>>>',is_privacy_popup_display, this.props)

    let withPaddingTop = AppConfig.navbar.coverContent ? false : false;
    let mustLogin = true;

    if (pathname.slice(4) === '') {
      mustLogin = false;
    } else if (pathname.slice(-4) === '/me/') {
      withPaddingTop = true;
    } else if (pathname.slice(-6) === '/cart/') {
      withPaddingTop = true;
    } else if (pathname.slice(-7) === '/video/') {
      withPaddingTop = true;
    } else if (pathname.slice(-16) === '/video-category/') {
      withPaddingTop = true;
    }

    return (
      <MuiWrapper>
        <Layout.Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <title>Merck ART</title>
            <meta name="description" content="Merck ART Forum" />
            <link rel="shortcut icon" href="/merck-logo.ico" />
          </Helmet>

          <Navbar passBreakpoint={passBreakpoint || withPaddingTop} />

          {(() => {
            if (mustLogin && !profile) {
              return (
                <div style={{ height: 'calc(100vh)', paddingTop: 100 }}>
                  <h2 style={{ textAlign: 'center' }}>請先登入</h2>
                </div>
              );
            }

            return <Layout.Content>{this.props.children}</Layout.Content>;
          })()}

          {is_privacy_popup_display && <FullScreenPopup
            title='隱私權條款'
            content={`臣亮言：先帝創業未半，而中道崩殂。今天下三分，益州
            疲弊，此誠危急存亡之秋也。然侍衛之臣，不懈於內；忠志之
            士，忘身於外者，蓋追先帝之殊遇，欲報之於陛下也。誠宜開
            張聖聽，以光先帝遺德，恢弘志士之氣；不宜妄自菲薄，引喻
            失義，以塞忠諫之路也。宮中府中，俱為一體，陟罰臧否，不
            宜異同。若有作姦犯科，及為忠善者，宜付有司，論其刑賞，
            以昭陛下平明之治，不宜篇私，使內外異法也。
             　　侍中、侍郎郭攸之、費褘、董允等，此皆良實，志慮忠純
            ，是以先帝簡拔以遺陛下。愚以為宮中之事，事無大小，悉以
            咨之，然後施行，必能裨補闕漏，有所廣益。將軍向寵，性行
            淑均，曉暢軍事，試用於昔日，先帝稱之曰「能」，是以眾議
            舉寵為督。愚以為營中之事，悉以咨之，必能使行陣和睦，優
            劣得所。親賢臣，遠小人，此先漢所以興隆也；親小人，遠賢
            臣，此後漢所以傾頹也。先帝在時，每與臣論此事，未嘗不歎
            息痛恨於桓、靈也。侍中、尚書、長史；參軍，此悉貞良死節
            之臣也，願陛下親之信之，則漢室之隆，可計日而待也。`}
            onClose={() => appActions.appState.show_privacy_popup(false)}
          />}

          <Footer openPrivacyPopup={() => {
            appActions.appState.show_privacy_popup(true)
            window.scrollTo(0, 0)
          }} />
        </Layout.Wrapper>
      </MuiWrapper>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === '//') {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== '/') {
      path += '/';
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector('html').scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

Layout.Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  padding-top: ${props =>
    props.withPaddingTop ? AppConfig.navbar.height : 0}px;
  display: flex;
  flex-direction: column;
`;

Layout.Content = styled.div`
  flex: 1;
  align-self: stretch;
  background-color: #2dbecd;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    is_privacy_popup_display: Selectors.appState.is_privacy_popup_display(state),
  }),
  ActionCreator
)(Layout);
