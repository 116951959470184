import ReactGA from 'react-ga';

// reference to https://github.com/react-ga/react-ga

// initial ga
let isInit = false;
let isDebug = true;

let _gaShell = (functionName, fn) => {
	return (...args) => {
		if (!isInit) {
			return;
		}

		if (isDebug) {
			let msg = args.reduce((acc, arg) => acc + (typeof arg === 'object' ? JSON.stringify(arg) : arg), '');
			console.log(`[${functionName}] ${msg}`)
		}

		return fn(...args)
	}
}


export function initGAIfNeeded(trackingId, debug=true, webHostname) {
	if (trackingId && !isInit) {
		ReactGA.initialize(trackingId, {
			trackingId,
			//debug,
			gaOptions: {
				cookieDomain: 'none',
			}
		})

		isInit = true;
		isDebug = debug;
	}
}


export const trackingPageView = _gaShell('trackingPageView', pathname => ReactGA.pageview(pathname))
export const trackingModalView = _gaShell('trackingModalView', modalName => ReactGA.modalview(modalName))

/*
+----------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+
|      key       |                                                                                                description                                                                                                 |
+----------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+
| category       | String. Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.                                                                                                    |
| action         | String. Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.                                                                                      |
| label          | String. Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc. |
| value          | Int. Optional. A means of recording a numerical value against an event. E.g. a rating, a score, etc.                                                                                                       |
| nonInteraction | Boolean. Optional. If an event is not triggered by a user interaction, but instead by our code (e.g. on page load, it should be flagged as a nonInteraction event to avoid skewing bounce rate data.       |
| transport      | String. Optional. This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.                                                                   |
+----------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+

*/
export const trackingUserAction = _gaShell('trackingUserAction', (...args) => ReactGA.ga('send', 'event', ...args))
export const trackingUserTiming = _gaShell('trackingUserTiming', (...args) => ReactGA.timing('send', 'timing', ...args))
export const ga = ReactGA.ga
export function withGAEvent(fn, gaEvent) {
	function wrapper(args) {
		trackingUserAction(gaEvent)
		return fn(...args)
	}
	return wrapper;
}

