export function memorizedOnce(fn, { debug = false } = {}) {
  // if one function is previously called with the same arguments
  // and  it already has a result, then just use previous result
  // without call it again.

  //TODO: should not import Store in this file, (note for circular dependency)
  let prevArgs = [],
    prevResult = undefined;

  return function memorizedOnceWrapper(...args) {
    if (isArgsEqual(prevArgs, args) && prevResult !== undefined) {
      console_log('args hit, use prev result');
      return prevResult;
    }
    prevResult = fn(...args);
    prevArgs = args;
    return prevResult;
  };

  function isArgsEqual(args1, args2) {
    if (args1.length === args2.length) {
      for (let i = 0; i < args1.length; i++) {
        if (args1[i] !== args2[i]) {
          return false;
        }
      }
    }
    return true;
  }

  function console_log(...args) {
    if (debug) {
      console.log(...args);
    }
  }
}

export function namedExportWithMemorizedOnce(namedExport) {
  let result = {};
  for (let key in namedExport) {
    let fn = namedExport[key];
    if (typeof fn === 'function') {
      result[key] = memorizedOnce(fn);
    }
  }
  return result;
}
