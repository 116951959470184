const Settings = {
    ghPageCustomDomain: "tel25.revtel.tech",
    webUrl: "https://revtel-merck-art.netlify.com",
    apiUrl: "https://merck-art-api.revtel.tech",
    workerPort: 12345,
    apiPrefix: "",
    SSR: false,
    cloudinaryUserId: "dfpqu00k2",
    debug: false,
    paypalSandboxEnv: true,
    paypalSandboxClientId: "AYCyvEJoKJ3378r-X2IozaB2HGknq7jd8U5aZjqF9jSl-Okj1kkIVNTpqBDej99zV4A2JQWzSjak7mOn",
    paypalProductionClientId: "???",
    facebookAppId: "1276459929127208",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
    googleAnalyticsId: "UA-127298118-1",
};

module.exports = Settings;

