import Actions from './Actions';

const initialState = {
  list: null,
  map: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.SET_ORDER:
      return {
        ...state,
        map: {
          ...state['map'],
          [payload.id]: payload,
        },
      };
    case Actions.SET_ORDERS:
      return {
        ...state,
        list: payload,
      };
    default:
      break;
  }

  return state;
}

export function getOrderDetailById(state, id) {
  return state['map'][id];
}

export function getOrderList(state) {
  console.log('state: ', state);

  return state['list'];
}
