export default function AppStateService(trackingId, isDebug) {
	const name = 'appState';

	return {
		name,
		actions: {
			SHOW_PRIVACY_POPUP: 'SHOW_PRIVACY_POPUP',
		},
		reducer: (state={show_privacy_popup:false}, {type, payload}) => {
			switch (type) {
				case 'SHOW_PRIVACY_POPUP':
					if (payload !== state.show_privacy_popup) {
						return {
							...state,
							show_privacy_popup: payload,
						}
					} else {
						return state
					}
				default:
					return state
			}
		},
		selectors: state => ({
			is_privacy_popup_display: ({appState}) => appState.show_privacy_popup,
		}),
		actionCreator: store => ({
			show_privacy_popup: s => store.dispatch({type: 'SHOW_PRIVACY_POPUP', payload: s})
		}),
	};
}
