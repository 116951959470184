import React from 'react';
import UnstatedForm from './UnstatedForm';

const Form = UnstatedForm;
class Toggle extends React.Component {
  constructor(props) {
    super(props);
    let { enable = false } = this.props;
    this.state = {
      enable,
    };
    this.setEnable = enable => this.setState({ enable });
  }

  render() {
    let { enable } = this.state;
    let render = this.props.children;
    return render({
      enable,
      setEnable: this.setEnable,
    });
  }
}

class Chooser extends React.Component {
  constructor(props) {
    super(props);
    let { options, selected = null, compare } = this.props;

    this.state = {
      options,
      selected,
    };

    this.defaultCompare = compare || ((o1, o2) => o1.id === o2.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.options !== this.props.options ||
      nextProps.selected !== this.props.selected
    ) {
      this.setState({
        options: nextProps.options,
        selected: nextProps.selected,
      });
    }
  }

  render() {
    let { options, selected } = this.state;
    let render = this.props.children;
    return render({
      options,
      selected,
      select: this._select,
      unselect: this._unselect,
      isSelected: this._isSelected,
    });
  }

  _isSelected = option => {
    let compare = this.defaultCompare.bind(null, option);
    let { selected } = this.state;

    if (!Array.isArray(selected)) {
      if (selected) {
        selected = [selected];
      } else {
        selected = [];
      }
    }

    return !!selected.find(compare);
  };

  _select = option => {
    let compare = this.defaultCompare.bind(null, option);
    let { onChange } = this.props;
    let { selected } = this.state;

    if (Array.isArray(selected)) {
      if (!selected.find(compare)) {
        let newSelected = [...selected, option];
        this.setState(
          {
            selected: newSelected,
          },
          () => {
            onChange && onChange(newSelected);
          }
        );
      }
    } else {
      this.setState({ selected: option }, () => {
        onChange && onChange(option);
      });
    }
  };

  _unselect = option => {
    let compare = this.defaultCompare.bind(null, option);
    let { onChange } = this.props;
    let { selected } = this.state;

    if (Array.isArray(selected)) {
      let index = selected.findIndex(compare);
      if (index > -1) {
        selected = [...selected];
        selected.splice(index, 1);
        this.setState({ selected }, () => {
          onChange && onChange(selected);
        });
      }
    } else {
      this.setState({ selected: null }, () => {
        onChange && onChange(null);
      });
    }
  };
}

class WithState extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state,
    };
  }

  render() {
    let render = this.props.children;
    return render({
      state: { ...this.state },
      setState: this.setState.bind(this),
    });
  }
}

export { Form, Toggle, Chooser, WithState };
