import React from 'react';
import PropsFactory from './PropsFactory';

class UnstatedForm extends React.Component {
  constructor(props) {
    super(props);

    let { init } = this.props;
    if (!init || typeof init !== 'function') {
      throw new Error('must have init prop (a function)');
    }

    let { values, editing = false } = init();
    if (!values) {
      throw new Error('you must fill in initial values');
    }

    this.state = {
      editing,
      values,
      valuesBeforeEdit: values,
      errors: {},
      submitting: false,
      submitError: null,
    };

    this.propsFactory = PropsFactory(this.setState.bind(this), {
      native: this.props.native,
    });
  }

  render() {
    let { validate, submit } = this.props;
    let {
      values,
      valuesBeforeEdit,
      errors,
      editing,
      submitting,
      submitError,
    } = this.state;
    let render = this.props.children;

    return render({
      values,
      errors,

      editProps: this.propsFactory.getEditProps({
        values,
        valuesBeforeEdit,
        editing,
      }),

      inputProps: this.propsFactory.getInputProps({
        values,
        errors,
        submitting,
        fnValidate: validate,
      }),

      submitProps: this.propsFactory.getSubmitProps({
        values,
        submitting,
        submitError,
        fnSubmit: submit,
        fnValidate: validate,
      }),
    });
  }
}

export default UnstatedForm;
