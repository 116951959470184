import React, { Component } from 'react'
import styled from 'styled-components'
import Link from '../../../src/Components/Link'

const FooterFactory = config => {
  class Footer extends Component {
    render () {
      let { extraStyle = '', openPrivacyPopup } = this.props

      return (
        <Wrapper extraStyle={extraStyle}>
          <div style={{color:'white', cursor:'pointer'}} onClick={openPrivacyPopup}>
            隱私權條款
          </div>
          <Content>
            <div className='copyright'>
              Copyright © 2018 Revteltech Limited. All rights reserved.
            </div>
          </Content>
        </Wrapper>
      )
    }
  }

  let Wrapper = styled.div`
    background-color: #194586;
    width: 100vw;
    padding: 40px 40px 40px 40px;
    color: #505050;
    display: flex;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
    }
    ${props => props.extraStyle};
  `

  let Content = styled.div`
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    flex-grow: ${props => (props.main ? 1 : 0)};
    min-width: 200px;
    color: white;


    & > .copyright {
      font-size: 18px;
      text-align:center;
    }

    & > .title {
      font-size: 15px;
      margin-bottom: 10px;
    }

    & > .menu {
      flex-grow: 1;
    }

    @media screen and (max-width: 500px) {
      margin-top: 20px;
      width: 100%;
    }
  `
  return Footer
}

export default FooterFactory;