import * as _User from './Reducers/User';
import * as _Lang from './Reducers/Lang';
import * as _Order from './Reducers/Order';
import * as _Product from './Reducers/Product';
import {getStore} from './Store'
import { namedExportWithMemorizedOnce } from './Utils/FpUtil';
import ParseQuery from './Utils/ParseQuery';
import { getSelectors } from './Services';

let User = namedExportWithMemorizedOnce(_User);
let Lang = namedExportWithMemorizedOnce(_Lang);
let Order = namedExportWithMemorizedOnce(_Order);
let Product = namedExportWithMemorizedOnce(_Product);

const selectors = {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLang: state => Lang.getLang(state.lang),
  getLoginUser: state => User.getLoginUser(state.user),
  getOrders: state => Order.getOrderList(state.order),
  getOrder: (state, id) => Order.getOrderDetailById(state.order, id),
  getProduct: (state, id) => Product.byId(state.product, id),
  getProductList: (state) => Product.list(state.product),
  ...getSelectors(getStore().getState()),
};

console.log(selectors);

export default selectors;
