import styled from 'styled-components';
import * as Css from '../Css';

export const Absolute = styled.div`
  ${Css.Absolute}
  ${props => props.css};
`;

export const Figure = styled.figure`
  ${Css.Background}
  ${props => props.css};

  & > h1 {
    padding:10px;
    background-color:rgba(255, 255, 255, 0.3);
  }
`;

export const Center = styled.div`
  ${Css.Center}
  ${props => props.css};
`;

export const MaxWidth = styled.div`
  max-width: ${props => props.width}px;
  margin: ${props => props.margin || '0 auto'};
  ${props => props.css};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'flex-start'};
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  ${props => props.css};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'flex-start'};
  ${props => props.css};
`;

export const Button = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: ${props => props.color || 'teal'};
  color: ${props => props.textColor || '#fff'};
  font-size: ${props => props.fontSize || '20px'};

  & :hover {
      font-weight: 900;
      background: ${props => props.hoverColor || props.color || 'teal'};
  }

  & :focus {
      background: ${props => props.focusColor || props.color || 'teal'};
      outline: none;
  }

  & :active {
      transform: scale(0.88);
  }

  & :disabled {
    background: ${props => props.disableColor || '#ccc'};
    color: ${props => props.disableTextColor || '#666'};
  }

  ${props => props.css || ''}
`;

export const FlatButton = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: font-weight 250ms ease-in-out,
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  color: ${props => props.color || 'black'};
  font-size: ${props => props.fontSize || '16px'};

  & :hover {
    transform: scale(1.05);
    color: ${props => props.color || 'black'};
  }

  & :focus {
    color: ${props => props.color || 'black'};
    outline: none;
  }

  & :active {
    transform: scale(0.88);
  }

  & :disabled {
    color: ${props => props.color || '#ccc'};
  }

  ${props => props.css || ''}
`;

export const FormField = styled.div`
  margin-bottom: 20px;
  display: flex;

  & > label {
      margin-right: 10px;
      flex: 1;
      font-weight: 700;
  }

  & > input {
      border: none;
      border-bottom: 1px solid #ccc;
      text-align: right;
      width: ${props => props.inputWidth || '100px'};
  }

  & > input:focus {
      outline: none;
  }

  & > select {
      width: ${props => props.selectWidth || props.inputWidth || '100px'};
      text-align-last: right;
  }

  & > select > option {
      direction: rtl;
  }

  ${props => props.css || ''}
`;

export const Spinner = styled.div`
  background-image: url('/spinner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: infinite-spinning 0.8s infinite;

  display: ${props => props.display || 'inline-block'};
  width: ${props => props.size || '80px'};
  height: ${props => props.size || '80px'};

  ${props => props.css || ''}
`;
