import CartServiceFactory from '../../src/Services/Cart';
import CatalogServiceFactory from '../../src/Services/Catalog';
import Config from '../../src/Domain/constants';
import { namedExportWithMemorizedOnce } from '../../src/Utils/FpUtil';
import VideoServiceFactory from './VideoLibrary'
import AppStateServiceFactory from './AppState';

console.log('plugin services');

const Services = {
  videos: VideoServiceFactory(Config.videos),
  cart: CartServiceFactory(Config.cart),
  appState: AppStateServiceFactory(),
  [CatalogServiceFactory().name]: CatalogServiceFactory(Config.catalog),
};

function getReducers() {
  return Object.keys(Services).reduce((acc, serviceName) => {
    acc[serviceName] = Services[serviceName].reducer;
    return acc;
  }, {});
}

function getActionCreators(store) {
  return Object.keys(Services).reduce((acc, serviceName) => {
    acc[serviceName] = Services[serviceName].actionCreator(store);
    return acc;
  }, {});
}

function getSelectors(store) {
  return Object.keys(Services).reduce((acc, serviceName) => {
    let selectors = Services[serviceName].selectors(store);
    acc[serviceName] = namedExportWithMemorizedOnce(selectors);
    return acc;
  }, {});
}

export { getReducers, getActionCreators, getSelectors };
