import React, { Component } from 'react'
import styled from 'styled-components';

export default class FullScreenPopup extends Component {
	render() {
		let {title, content, zIndex, onClose} = this.props;
		const handleClick = e => {
			e.stopPropagation();
			onClose && onClose()
		}

		return (
			<Overlay onClick={handleClick}>
				<div className='popup'>
					<div className='title'>
						{title}
						<div className='cancel-button'>x</div>
					</div>
					<div className='content'>{content}</div>
				</div>
			</Overlay>
		)
	}
}

const Overlay = styled.div `
	position: absolute;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 1000;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	& > .popup {
		padding: 10px;
		border-radius: 10px;
		width: 90%;
		background-color:white;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		& > .content {
			margin-top:10px;
		}

		& > .title {
			font-size: 20px;
			width: 100%;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			& > .cancel-button {

			}
		}
	}


`