// import categories from '../../content/categories.json';
// import products from '../../content/products.json';
import ApiProxy from '../../src/ApiProxy'

export default function CatalogService(config = {}) {
  const name = 'videos';

  function reducer(state = undefined, { actions, payload }) {
    return {}
  }

  function actionCreator(store) {
    return {
      browseCategory: async () => {
        try {
          return ApiProxy.get({
            path: `/api/browse/categories/`,
          });
        } catch (ex) {
          console.warn(ex);
          throw ex;
        }
      },

      fetchItemDetail: async (id) => {
        try {
          return ApiProxy.get({
            path: `/api/items/${id}/`,
          });
        } catch (ex) {
          console.warn(ex);
          throw ex;
        }
      },

      fetchCategoryDetail: async (id) => {
        try {
          return ApiProxy.get({
            path: `/api/browse/categories/${id}/`,
          });
        } catch (ex) {
          console.warn(ex);
          throw ex;
        }
      },

      addFavorite: async (id) => {
        try {
          return ApiProxy.post({
            path: `/api/favorite/`,
            data: {
              item: id,
              action: 'add'
            }
          });
        } catch (ex) {
          console.warn(ex);
          throw ex;
        }
      },

      removeFavorite: async (id) => {
        try {
          return ApiProxy.post({
            path: `/api/favorite/`,
            data: {
              item: id,
              action: 'remove'
            }
          });
        } catch (ex) {
          console.warn(ex);
          throw ex;
        }
      },
    };
  }

  function selectors(state) {
    return {
      getItemById: id => state[name].itemsMap[id],
    }
  }

  return {
    name,
    reducer,
    actionCreator,
    selectors,
  };
}
