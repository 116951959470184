import React, { Component } from 'react';
import 'rc-dialog/assets/index.css';
import './Dialog.css'; // style override for `overflow: hidden`
import DialogImpl from 'rc-dialog';

export default class Dialog extends Component {
  render() {
    let { extraStyle = {} } = this.props;

    return (
      <DialogImpl
        className={'revDialogHocCustomStyle'}
        visible={true}
        onClose={() => 0}
        afterClose={() => 0}
        wrapClassName="center"
        animation="fade"
        maskAnimation="fade"
        zIndex={10000}
        closable={false}
        maskClosable={false}
      >
        <div style={{ padding: 0, ...extraStyle }}>{this.props.children}</div>
      </DialogImpl>
    );
  }
}
